import * as Sentry from '@sentry/browser'
import React, { createContext, useState, useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'

import { IPDATACO_KEY } from '../../config'

const UserContext = createContext({})

const UserContextProvider = ({ children }) => {
  const [campaign] = useQueryParam('campaign', StringParam)
  const [tofu] = useQueryParam('tofu', StringParam)

  // UTM
  const [utmSource] = useQueryParam('utm_source', StringParam)
  const [utmMedium] = useQueryParam('utm_medium', StringParam)
  const [utmCampaign] = useQueryParam('utm_campaign', StringParam)
  const [utmTerm] = useQueryParam('utm_term', StringParam)
  const [utmContent] = useQueryParam('utm_content', StringParam)

  const [locationData, setLocationData] = useState(null)
  const [userMeta, setUserMeta] = useState(null)
  const [campaignData, setCampaignData] = useState({})

  // const [gclid, setGclid] = useState(null)
  // const [gaClientId, setGaClientId] = useState(null)

  const pathname =
    typeof window !== 'undefined' ? window.location.pathname : undefined

  const context = {
    locationData,
    userMeta,
    campaignData,
    // gclid,
    // gaClientId,
  }

  useEffect(() => {
    const newCampaignData = {}
    if (
      (tofu !== undefined || pathname?.includes('tofu')) &&
      campaignData.audienceLevel !== 'tofu'
    )
      newCampaignData.audienceLevel = 'tofu'
    if (campaign && campaignData.campaign !== campaign)
      newCampaignData.campaign = campaign
    // UTM
    if (utmSource && campaignData.utmSource !== utmSource)
      newCampaignData.utmSource = utmSource
    if (utmMedium && campaignData.utmMedium !== utmMedium)
      newCampaignData.utmMedium = utmMedium
    if (utmCampaign && campaignData.utmCampaign !== utmCampaign)
      newCampaignData.utmCampaign = utmCampaign
    if (utmTerm && campaignData.utmTerm !== utmTerm)
      newCampaignData.utmTerm = utmTerm
    if (utmContent && campaignData.utmContent !== utmContent)
      newCampaignData.utmContent = utmContent
    if (Object.keys(newCampaignData).length > 0) {
      setCampaignData({ ...campaignData, ...newCampaignData })
    }
  }, [
    campaignData,
    tofu,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    campaign,
    pathname,
  ])

  useEffect(() => {
    const { languages } = window.navigator
    if (!Array.isArray(languages) || languages.length < 1) return

    const englishFirstLang = languages[0].startsWith('en')
    const englishSecondLang =
      !englishFirstLang && languages.some((lang) => lang.startsWith('en'))

    setUserMeta({
      englishFirstLang,
      englishSecondLang,
    })
  }, [])

  useEffect(() => {
    if (!window.fetch) return

    // defer loading location data unless on contact page
    // TODO cache result to localStorage
    const delay = window.location.pathname.includes('contact') ? 0 : 2000

    setTimeout(() => {
      window
        .fetch(`https://api.ipdata.co?api-key=${IPDATACO_KEY}`)
        .then((res) => {
          if (res.ok !== true)
            throw new Error(
              `Couldn't fetch IP: ${res.status} ${res.statusText}`,
            )
          return res.json()
        })
        .then((data) => {
          const { is_anonymous, is_threat } = data.threat
          if (is_anonymous || is_threat) return

          setLocationData({
            ip: data.ip,
            countryCode: data.country_code,
            countryName: data.country_name,
            timezone: {
              name: data.time_zone.name,
              abbr: data.time_zone.abbr,
              offset: data.time_zone.offset,
            },
            currency: {
              code: data?.currency.code,
            },
            languages: data.languages,
          })
        })
        .catch((err) => {
          Sentry.withScope((scope) => {
            scope.setLevel('warning')
            Sentry.captureException(err)
          })
        })
    }, delay)
  }, [setLocationData])

  useEffect(() => {
    const currencyCode = locationData?.currency.code
    const exchangeRate = locationData?.currency.exchangeRate

    if (currencyCode && !exchangeRate) {
      if (currencyCode === 'GBP') {
        const newLocationData = { ...locationData }
        newLocationData.currency.exchangeRate = 1
        setLocationData(newLocationData)
      } else {
        window
          .fetch(`/.netlify/functions/fx-rate?currency=${currencyCode}`)
          .then((res) => {
            if (res.ok !== true)
              throw new Error(
                `Couldn't fetch exchange rate for ${currencyCode}`,
              )
            return res.json()
          })
          .then((data) => {
            const newLocationData = { ...locationData }
            newLocationData.currency.exchangeRate = data.rate
            setLocationData(newLocationData)
          })
          .catch((err) => {
            Sentry.withScope((scope) => {
              scope.setLevel('warning')
              Sentry.captureException(err)
            })
          })
      }
    }
  }, [locationData])

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export { UserContext, UserContextProvider }
