exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-entrepreneur-visas-js": () => import("./../../../src/pages/about-entrepreneur-visas.js" /* webpackChunkName: "component---src-pages-about-entrepreneur-visas-js" */),
  "component---src-pages-abuja-seminar-register-js": () => import("./../../../src/pages/abuja-seminar/register.js" /* webpackChunkName: "component---src-pages-abuja-seminar-register-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-citizenship-index-js": () => import("./../../../src/pages/citizenship/index.js" /* webpackChunkName: "component---src-pages-citizenship-index-js" */),
  "component---src-pages-citizenship-second-citizenship-dominica-js": () => import("./../../../src/pages/citizenship/second-citizenship-dominica.js" /* webpackChunkName: "component---src-pages-citizenship-second-citizenship-dominica-js" */),
  "component---src-pages-citizenship-second-citizenship-portugal-js": () => import("./../../../src/pages/citizenship/second-citizenship-portugal.js" /* webpackChunkName: "component---src-pages-citizenship-second-citizenship-portugal-js" */),
  "component---src-pages-consultation-booking-success-js": () => import("./../../../src/pages/consultation/booking-success.js" /* webpackChunkName: "component---src-pages-consultation-booking-success-js" */),
  "component---src-pages-consultation-webinar-offer-tsx": () => import("./../../../src/pages/consultation/webinar-offer.tsx" /* webpackChunkName: "component---src-pages-consultation-webinar-offer-tsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-tsx": () => import("./../../../src/pages/covid-19.tsx" /* webpackChunkName: "component---src-pages-covid-19-tsx" */),
  "component---src-pages-cyprus-citizenship-by-investment-js": () => import("./../../../src/pages/cyprus/citizenship-by-investment.js" /* webpackChunkName: "component---src-pages-cyprus-citizenship-by-investment-js" */),
  "component---src-pages-cyprus-index-js": () => import("./../../../src/pages/cyprus/index.js" /* webpackChunkName: "component---src-pages-cyprus-index-js" */),
  "component---src-pages-cyprus-residency-by-investment-js": () => import("./../../../src/pages/cyprus/residency-by-investment.js" /* webpackChunkName: "component---src-pages-cyprus-residency-by-investment-js" */),
  "component---src-pages-dubai-meetings-register-js": () => import("./../../../src/pages/dubai-meetings/register.js" /* webpackChunkName: "component---src-pages-dubai-meetings-register-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ireland-employer-permit-plan-js": () => import("./../../../src/pages/ireland/employer-permit-plan.js" /* webpackChunkName: "component---src-pages-ireland-employer-permit-plan-js" */),
  "component---src-pages-legal-notices-js": () => import("./../../../src/pages/legal-notices.js" /* webpackChunkName: "component---src-pages-legal-notices-js" */),
  "component---src-pages-lp-live-work-uk-ireland-index-js": () => import("./../../../src/pages/lp/live-work-uk-ireland/index.js" /* webpackChunkName: "component---src-pages-lp-live-work-uk-ireland-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-benefits-js": () => import("./../../../src/pages/service-benefits.js" /* webpackChunkName: "component---src-pages-service-benefits-js" */),
  "component---src-pages-the-team-js": () => import("./../../../src/pages/the-team.js" /* webpackChunkName: "component---src-pages-the-team-js" */),
  "component---src-pages-uk-business-investor-visas-js": () => import("./../../../src/pages/uk/business-investor-visas.js" /* webpackChunkName: "component---src-pages-uk-business-investor-visas-js" */),
  "component---src-pages-uk-family-visa-js": () => import("./../../../src/pages/uk/family-visa.js" /* webpackChunkName: "component---src-pages-uk-family-visa-js" */),
  "component---src-pages-uk-index-js": () => import("./../../../src/pages/uk/index.js" /* webpackChunkName: "component---src-pages-uk-index-js" */),
  "component---src-pages-uk-innovator-founder-visa-js": () => import("./../../../src/pages/uk/innovator-founder-visa.js" /* webpackChunkName: "component---src-pages-uk-innovator-founder-visa-js" */),
  "component---src-pages-uk-innovator-visa-js": () => import("./../../../src/pages/uk/innovator-visa.js" /* webpackChunkName: "component---src-pages-uk-innovator-visa-js" */),
  "component---src-pages-uk-iom-tech-visa-js": () => import("./../../../src/pages/uk/iom-tech-visa.js" /* webpackChunkName: "component---src-pages-uk-iom-tech-visa-js" */),
  "component---src-pages-uk-isle-of-man-entrepreneur-visa-js": () => import("./../../../src/pages/uk/isle-of-man-entrepreneur-visa.js" /* webpackChunkName: "component---src-pages-uk-isle-of-man-entrepreneur-visa-js" */),
  "component---src-pages-uk-isle-of-man-innovator-visa-js": () => import("./../../../src/pages/uk/isle-of-man-innovator-visa.js" /* webpackChunkName: "component---src-pages-uk-isle-of-man-innovator-visa-js" */),
  "component---src-pages-uk-isle-of-man-sole-representative-visa-js": () => import("./../../../src/pages/uk/isle-of-man-sole-representative-visa.js" /* webpackChunkName: "component---src-pages-uk-isle-of-man-sole-representative-visa-js" */),
  "component---src-pages-uk-legal-services-js": () => import("./../../../src/pages/uk/legal-services.js" /* webpackChunkName: "component---src-pages-uk-legal-services-js" */),
  "component---src-pages-uk-self-sponsorship-visa-js": () => import("./../../../src/pages/uk/self-sponsorship-visa.js" /* webpackChunkName: "component---src-pages-uk-self-sponsorship-visa-js" */),
  "component---src-pages-uk-skilled-worker-visa-js": () => import("./../../../src/pages/uk/skilled-worker-visa.js" /* webpackChunkName: "component---src-pages-uk-skilled-worker-visa-js" */),
  "component---src-pages-uk-sole-representative-visa-js": () => import("./../../../src/pages/uk/sole-representative-visa.js" /* webpackChunkName: "component---src-pages-uk-sole-representative-visa-js" */),
  "component---src-pages-uk-start-up-visa-tsx": () => import("./../../../src/pages/uk/start-up-visa.tsx" /* webpackChunkName: "component---src-pages-uk-start-up-visa-tsx" */),
  "component---src-pages-uk-student-visa-js": () => import("./../../../src/pages/uk/student-visa.js" /* webpackChunkName: "component---src-pages-uk-student-visa-js" */),
  "component---src-pages-uk-tier-1-entrepreneur-visa-index-js": () => import("./../../../src/pages/uk/tier-1-entrepreneur-visa/index.js" /* webpackChunkName: "component---src-pages-uk-tier-1-entrepreneur-visa-index-js" */),
  "component---src-pages-uk-tier-1-entrepreneur-visa-kingdom-entrepreneur-js": () => import("./../../../src/pages/uk/tier-1-entrepreneur-visa/kingdom-entrepreneur.js" /* webpackChunkName: "component---src-pages-uk-tier-1-entrepreneur-visa-kingdom-entrepreneur-js" */),
  "component---src-pages-uk-tier-4-sponsor-licence-js": () => import("./../../../src/pages/uk/tier-4-sponsor-licence.js" /* webpackChunkName: "component---src-pages-uk-tier-4-sponsor-licence-js" */),
  "component---src-pages-uk-visa-sponsor-licence-for-employers-js": () => import("./../../../src/pages/uk/visa-sponsor-licence-for-employers.js" /* webpackChunkName: "component---src-pages-uk-visa-sponsor-licence-for-employers-js" */),
  "component---src-pages-uk-visitor-visa-js": () => import("./../../../src/pages/uk/visitor-visa.js" /* webpackChunkName: "component---src-pages-uk-visitor-visa-js" */),
  "component---src-pages-usa-e-2-treaty-investor-visa-js": () => import("./../../../src/pages/usa/e2-treaty-investor-visa.js" /* webpackChunkName: "component---src-pages-usa-e-2-treaty-investor-visa-js" */),
  "component---src-pages-usa-index-js": () => import("./../../../src/pages/usa/index.js" /* webpackChunkName: "component---src-pages-usa-index-js" */),
  "component---src-pages-webinar-register-caribbean-js": () => import("./../../../src/pages/webinar/register-caribbean.js" /* webpackChunkName: "component---src-pages-webinar-register-caribbean-js" */),
  "component---src-pages-webinar-register-ireland-js": () => import("./../../../src/pages/webinar/register-ireland.js" /* webpackChunkName: "component---src-pages-webinar-register-ireland-js" */),
  "component---src-pages-webinar-register-js": () => import("./../../../src/pages/webinar/register.js" /* webpackChunkName: "component---src-pages-webinar-register-js" */),
  "component---src-pages-webinar-register-uk-js": () => import("./../../../src/pages/webinar/register-uk.js" /* webpackChunkName: "component---src-pages-webinar-register-uk-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

